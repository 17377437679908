@use "@angular/material" as mat;
@use "./app/layout/layout-component.theme";
@use './theme-colors' as theme;

@include mat.core();

$theme: mat.define-theme((color: (theme-type: light,
                primary: theme.$primary-palette,
                //   secondary: theme.$tertiary-palette,
                tertiary: theme.$tertiary-palette ),
            density: (scale: 0,
            ),
            typography: (plain-family: Inter,
                brand-family: Inter,
                bold-weight: 700,
                medium-weight: 600,
                regular-weight: 400,
            ),
        ));

$border-radius: 6px;

:root {
    @include mat.all-component-themes($theme);
    @include layout-component.theme($theme);

    @include mat.table-overrides((background-color: mat.get-theme-color($theme, neutral, 100),
            row-item-outline-color: mat.get-theme-color($theme, neutral-variant, 90)));

    @include mat.paginator-overrides((container-background-color: mat.get-theme-color($theme, neutral, 100)));

    @include mat.card-overrides((elevated-container-color: mat.get-theme-color($theme, neutral, 100),
            elevated-container-shape: $border-radius));

    @include mat.button-overrides((filled-container-shape: $border-radius,
            outlined-container-shape: $border-radius,
            text-container-shape: $border-radius ));

    @include mat.tabs-overrides((label-text-size: 16px,
            active-label-text-color: mat.get-theme-color($theme, primary, 40),
            active-hover-label-text-color: mat.get-theme-color($theme, primary, 40),
            active-focus-label-text-color: mat.get-theme-color($theme, primary, 40),
            divider-color: mat.get-theme-color($theme, neutral-variant, 70)));

    @include mat.dialog-overrides((container-shape: $border-radius,
            container-color: mat.get-theme-color($theme, neutral, 100),
            subhead-size: 24px,
            subhead-weight: 700));

    @include mat.snack-bar-overrides((container-shape: $border-radius,
        ));

    @include mat.menu-overrides((divider-bottom-spacing: 16px,
            divider-top-spacing: 16px,
            container-shape: $border-radius,
            container-color: mat.get-theme-color($theme, neutral, 100)));

    @include mat.expansion-overrides((container-background-color: rgba(0, 0, 0, 0),
            container-text-color: red,
            header-expanded-state-height: 48px,
            header-collapsed-state-height: 48px));

    //Overrides for components/styles without built-in support.
    .mat-mdc-icon-button {
        border-radius: $border-radius !important;

        .mat-mdc-button-persistent-ripple {
            border-radius: $border-radius !important;
        }
    }

    .mat-mdc-menu-item {
        border-radius: $border-radius !important;
    }

    .mat-mdc-menu-panel {
        min-width: 240px !important;
    }

    mat-snack-bar-container {
        margin-bottom: 24px !important;
    }

    .mat-mdc-menu-content {
        padding: 16px !important;
    }
}



* {
    padding: 0;
    margin: 0;
    letter-spacing: 0 !important;
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    font-size: 16px;
}

body {
    margin: 0;
    font-family: Inter, "Helvetica Neue", sans-serif;
    background-image: linear-gradient(135deg, mat.get-theme-color($theme, primary, 90) 0%, mat.get-theme-color($theme, tertiary, 90) 100%);
}

a {
    color: mat.get-theme-color($theme, primary, 40);
    text-decoration: none;
    @include mat.button-overrides((text-horizontal-padding:0,
            text-hover-state-layer-opacity: 0,
            text-focus-state-layer-opacity: 0,
            text-pressed-state-layer-opacity: 0,
            ripple-color: mat.get-theme-color($theme, neutral, 100)));
}

.mat-mdc-menu-content {
    .initials {
        font-size: 18px;
        min-width: 48px;
        min-height: 48px;
        text-align: center;
        font-weight: 600;
        line-height: 48px;
        border-radius: 50%;
        background-color: mat.get-theme-color($theme, secondary, 60);
        color: mat.get-theme-color($theme, neutral, 100);
    }
}

mat-dialog-container {
    h2 {
        white-space: nowrap;
    }
}

.user-details {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-bottom: 16px;

    p {
        font-weight: 400;
        color: mat.get-theme-color($theme, neutral, 50);
        margin-bottom: 0;
    }
}

.user-menu-trigger {
    color: var(--primary-color) !important;
    
    @include mat.button-overrides((
        text-label-text-size: 16px
     ));

    mat-icon {
        font-size: 36px !important;
        height: 36px !important;
        width: 36px !important;
    }
}

.component-header {
    margin-bottom: 24px;
}

.breadcrumbs {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 16px;
}

mat-card {
    @include mat.card-overrides((elevated-container-color: mat.get-theme-color($theme, neutral, 100),
            elevated-container-shape: 12px));

    padding: 8px;
}

mat-chip {
    @include mat.chips-overrides((container-shape-radius: $border-radius,
            outline-width: 0,
            disabled-label-text-color: mat.get-theme-color($theme, neutral, 10),
            elevated-disabled-container-color: mat.get-theme-color($theme, neutral, 95),
        ));
}

mat-chip.ready-for-review {
    @include mat.chips-overrides((elevated-disabled-container-color: mat.get-theme-color($theme, primary, 90),
        ));
}

mat-chip.background-check--complete {
    @include mat.chips-overrides((elevated-disabled-container-color: rgba(102, 204, 0, .25)));
}


/* You can add global styles to this file, and also import other style files */
* {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
}

:root {
    /*--primary-color: #00305C; */
    --primary-color: rgba(0, 0, 0, 0);
    --secondary-color: rgba(0, 0, 0, 0);
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    background-color: rgb(247, 247, 249);
}

h2 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 24px;
}

h3 {
    margin-bottom: 24px;
}

p {
    margin-bottom: 24px;
    line-height: 1.5;
}

input,
button,
select,
option {
    font-size: 16px;

    /* appearance: none;
  -webkit-appearance: none; */
}

ul {
    margin-bottom: 24px;
}

li {
    margin: 12px 36px;
}

button {
    padding: 12px 24px;
    margin-right: 12px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 250ms ease;
}

.primary {
    background-color: var(--primary-color);
    font-weight: 700;
    color: #FFF;
}

.primary:hover {
    background-color: color-mix(in srgb, var(--primary-color) 85%, #000);
}

.primary:active {
    /* background-color: color-mix(in srgb, var(--primary-color) 70%, #000); */
    background-color: color-mix(in srgb, var(--primary-color) 85%, #FFF);
}