@use "@angular/material" as mat;

@mixin theme($theme) {
    @include mat.toolbar-overrides((container-background-color: mat.get-theme-color($theme, neutral, 100),
            container-text-color: "#FFF",
            title-text-weight: bold));

    @include mat.sidenav-overrides((container-background-color: mat.get-theme-color($theme, primary, 95),
            container-shape: 0,
        ));

    mat-sidenav {
        @include mat.list-overrides((
            active-indicator-color: rgba(0,0,0,0),
            active-indicator-shape: 0,
            list-item-hover-label-text-color: mat.get-theme-color($theme, neutral, 50),
            list-item-label-text-color: mat.get-theme-color($theme, neutral, 0),
            list-item-focus-label-text-color: mat.get-theme-color($theme, neutral, 50),
            // list-item-label-text-weight: 600,
            list-item-label-text-tracking: 0,
            list-item-hover-state-layer-opacity: 0,
            list-item-focus-state-layer-opacity: 0,
        ));
    }

    .form-link {
        color: mat.get-theme-color($theme, neutral, 30);
    }

    .form-link:hover {
        color: var(--primary-color);
    }

    .sidenav-content {
        @media (pointer: fine) {
            &::-webkit-scrollbar-thumb {
                background-color: mat.get-theme-color($theme, primary);
            }
        }
    }
}